<template>
  <tr class="plating-row">
    <td :class="`${fontSize} align-left pl-1`">{{ title }}</td>
    <td :class="`${fontSize} text-right text-large`">
      <span class="d-print-none" v-if="readOnly">
       {{ formatWeight(cookedAmountTotalSmall) }}g
      </span>
      <v-form class="d-print-none" v-if="!readOnly"
              ref="sizeFormSmall" v-model="smallValid">
        <v-text-field
          style=""
          :class="`${fontSize} input-summary d-print-none`"
          dense
          type="number"
          v-model="cookedAmountTotalSmall"
          label=""
          suffix="g"
          @change="updateComponentAmount(cookedAmountTotalSmall, 'small')"
          @blur="updateCookedAmountTotals()"
          :disabled="!component.id"
        />
      </v-form>
      <span class="d-none d-print-block">{{ formatWeight(cookedAmountTotalSmall) }}g      </span>
    </td>
    <td :class="`${fontSize} text-right text-large`">
      <span class="">{{
          formatWeight(cookedAmountTotal)
        }}g
    </span>
    </td>
    <td :class="`${fontSize} text-right text-large`">
      <span class="d-print-none" v-if="readOnly">{{ formatWeight(cookedAmountTotalLarge) }}g</span>
      <v-form v-if="!readOnly" ref="sizeFormLarge" v-model="largeValid" class="d-print-none">
        <v-text-field
          style=""
          :class="`${fontSize} input-summary d-print-none`"
          dense
          type="number"
          v-model="cookedAmountTotalLarge"
          label=""
          suffix="g"
          @change="updateComponentAmount(cookedAmountTotalLarge,'large')"
          @blur="updateCookedAmountTotals()"
          :disabled="!component.id"
        />
      </v-form>
      <span class="d-none d-print-block">
        {{ formatWeight(cookedAmountTotalLarge) }}g
      </span>
    </td>

    <td class="d-print-none text-right">
      <span v-if="readOnly && component.id && component.monosized" class="text-right">
        <v-icon>mdi-checkbox-marked-outline</v-icon>
      </span>
      <v-checkbox
        v-if="!readOnly && component.id"
        :dense="dense"
        class="right"
        v-model="component.monosized"
        @change="updateComponent({id: component.id, component})"
      />
    </td>
  </tr>

</template>

<script>

import {mapActions} from 'vuex';
import {ensureNumber} from '@/store/utils';

export default {
  components: {},
  props: {
    readOnly: Boolean,
    title: String,
    subtitle: String,
    component: Object,
    ingredients: Array,
    summary: Object,
    dense: Boolean,
    fontSize: {type: String, default: 'text-large', required: false}
  },
  data() {
    return {
      editIngredient: {},
      amountWidth: this.dense ? '20px;' : '60px',
      ingredientWidth: this.dense ? '' : '*',
      amountClass: this.dense ? 'px-1 py-0 my-0 text-right' : 'text-large',
      amountHeaderClass: this.dense ? 'pa-2 text-xs-left' : 'text-left',
      cookedAmountTotalSmall: 0,
      cookedAmountTotalLarge: 0,
      rules: {
        eq(size) {
          return v => v == size || 'You cannot customise the size of "General ingredient".  To do this, add a component using the ingredients you wish to customise'
        }
      },
      noRules: [
        () => true
      ],
      smallValid: true,
      largeValid: true
    }
  },
  mounted() {
    // console.log('mounted - this.activeIngredients)', JSON.stringify(this.activeIngredients));
    //this.activeIngredients.forEach(this.formatCookedAmount);
    this.updateCookedAmountTotals();
  }
  ,
  methods: {
    ...
      mapActions([
        'updateComponent',
      ]),

    formatCookedAmount(i) {
      // i.cookedAmount = this.formatWeight(i.cookedAmount);
      if (!(i.cookedAmount < 0.1)) {
        this.$set(i, 'cookedAmount', this.formatWeight(i.cookedAmount));
        this.$set(i, 'cookedAmountSmall', this.formatWeight(i.cookedAmount * this.component.small_ratio));
        this.$set(i, 'cookedAmountLarge', this.formatWeight(i.cookedAmount * this.component.large_ratio));
      } else {
        this.$set(i, 'cookedAmountSmall', (i.cookedAmount * this.component.small_ratio));
        this.$set(i, 'cookedAmountLarge', (i.cookedAmount * this.component.large_ratio));
      }

    }
    ,
    getRawAmount(ingredient) {
      let {cookedAmount, percentChange} = ingredient;
      cookedAmount = ensureNumber(cookedAmount);
      percentChange = ensureNumber(percentChange)
      // if (ingredient.percentYield) {
      //   return (cookedAmount / (ingredient.percentYield / 100));
      // }
      if (percentChange) {
        return cookedAmount + (cookedAmount * (percentChange / 100));
      } else {
        return cookedAmount;
      }
    }
    ,
    formatWeight(value) {
      if (value === undefined || Number.isNaN(value)) {
        // console.log('warning formatWeight called with ', value);
        return '0';
      }
      value = Number.parseFloat(value);
      // console.log('formatWeight', [value, value.toFixed()]);
      // return value;
      return value.toFixed(0);
    }
    ,
    sum(array) {
      return array.reduce((ac, i) => ac + ensureNumber(i), 0);
    }
    ,
    // eslint-disable-next-line no-unused-vars
    async updateComponentAmount(newAmount, mealSize) {
      console.log('updating updateComponentAmount', {component: this.component, newAmount});
      this.$nextTick(async () => {
        newAmount = ensureNumber(newAmount);
        if (mealSize === 'small') {
          this.component.small_ratio = (newAmount / this.cookedAmountTotal);
        }
        if (mealSize === 'large') {
          this.component.large_ratio = (newAmount / this.cookedAmountTotal);
        }
        console.log('saving component ratios ', this.component);
        // this.$set(ingredient, 'editing', false);
        await this.$store.dispatch('updateComponent', {id: this.component.id, component: this.component});
      });
    }
    ,
    removeIngredient(ingredient) {
      // console.log('TODO: removing ', [index, this.ingredients[index]]);
      // this.$delete(this.ingredients,index);
      this.$set(ingredient, 'toDelete', true);
      this.$store.dispatch('removeIngredient', {ingredient});
    }
    ,
    updateCookedAmountTotals() {
      const calculateCookedAmountTotal = this.calculateCookedAmountTotal();
      if (this.component.monosized) {
        this.cookedAmountTotalSmall = this.formatWeight(calculateCookedAmountTotal);
        this.cookedAmountTotalLarge = this.formatWeight(calculateCookedAmountTotal);
      } else {
        this.cookedAmountTotalSmall = this.formatWeight(this.component.small_ratio * calculateCookedAmountTotal);
        this.cookedAmountTotalLarge = this.formatWeight(this.component.large_ratio * calculateCookedAmountTotal);
      }
    }
    ,
    calculateCookedAmountTotal() {
      return this.sum(
        this.activeIngredients.map(d => d.cookedAmount)
      );
    }
  },
  computed: {
    activeIngredients() {
      return this.ingredients
        .filter(i => !i.ignore_prep_weight)
        .filter(i => !i.toDelete)
    },
    cookedAmountTotal() {
      return this.calculateCookedAmountTotal();
    },
    rawTotalSum() {
      return this.sum(
        this.activeIngredients.map(this.getRawAmount));
    }
  }
}

</script>

<style>

.plating-row td {
  border-bottom: dashed black 1px;
}

.text-large {
  font-size: 28px !important;
}

.input-summary .v-text-field__slot input {
  text-align: right;
}

.v-input {
  text-align: right;
}

</style>
